import * as React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import SEO from "src/components/SEO.js"

import Wrapper from "src/components/global/Wrapper.js";
import colors from "src/assets/styles/colors.js";
import BodyText from "src/components/typography/BodyText.js";
import BigTitle from "src/components/typography/BigTitle.js";
import Slider from "src/components/global/Slider.js";
import mediaQuery from "src/assets/styles/mediaQuery";
import sizes from "src/assets/styles/sizes.js";

import Cta from "src/components/global/Cta.js";
import typographySizes from "../assets/styles/typographySizes";

const Header = styled(Wrapper)`
  padding-top: 260px;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 100px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-top: 18.025vw;
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 25px;
  position: relative;
  ::before {
    content: "01";
    position: absolute;
    top: 0;
    left: -10px;
    transform: translate(-100%, 9px);
    vertical-align: super;
    font-size: 50%;
    color: ${colors.midGrey};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-bottom: 20px;

    ::before {
      transform: translate(-100%, 3px);
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 1.733vw;
    ::before {
      left: -0.693vw;
      transform: translate(-100%, 0.623vw);
    }
  }
`;

const Descritpion01 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  align-items: flex-start;
  .marginP {
    p {
      margin-bottom: 20px;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-bottom: 30px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 3.466vw;
    .marginP {
      p {
        margin-bottom: 1.386vw;
      }
    }
  }
`;

const DescritpionLeft01 = styled.div`
  margin-left: 8.33%;
  width: 41.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
    width: 100%;
  }
`;

// const DescritpionSec = styled(BodyText)`
//   margin-left: 8.33%;
//   width: 41.66%;
//   @media (max-width: ${mediaQuery.tablet}) {
//     margin-left: 0%;
//     width: 100%;
//   }
// `;

const StyledImg01 = styled(Img)`
  width: 58.33%;
  margin-left: 8.33%;

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-left: 0%;
  }
`;

const DarkBlueBg = styled.div`
  width: 100%;
  padding-top: 100px;
  position: relative;
  /* top: -100px; */
  background-color: ${colors.deepBlue};
  ::before {
    content: "";
    position: absolute;
    top: -100px;
    height: 100px;
    background-color: ${colors.deepBlue};
    width: 100vw;
    z-index: -1;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 50px;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-top: 6.933vw;
    /* top: -6.933vw; */
  }
`;

const ConsultationWrapper = styled.div`
  padding-bottom: 190px;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 70px;
    flex-direction: column-reverse;
  }
  @media (min-width: ${mediaQuery.max}) {
    padding-bottom: 13.167vw;
  }
`;

const ConsultationImg = styled(Img)`
  width: calc(50% - 10px);
  margin-top: 160px;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 0;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(50% - 0.693vw);
    margin-top: 11.088vw;
  }
`;

const ConsultationTextWrapper = styled.div`
  width: calc(50% - 10px);
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-bottom: 10px;
  }
  @media (min-width: ${mediaQuery.max}) {
    width: calc(50% - 0.693vw);
  }
`;

const ConsultationTitle = styled(StyledBigTitle)`
  ::before {
    content: "02";
    color: ${colors.mainColor};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
  }
`;

const ConsultationDescritpionWrapper = styled.div`
  margin-left: 16.66%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
  }
`;

const ConsultationDescritpion = styled.div`
  .marginP {
    p {
      color: ${colors.white};

      margin-bottom: 20px;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    .marginP {
      p {
        margin-bottom: 1.386vw;
      }
    }
  }
`;

const ConsultationDescritpionDesktop = styled(ConsultationDescritpion)`
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const ConsultationDescritpionMobile = styled(ConsultationDescritpion)`
  display: none;
  p {
    font-weight: 300;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
    margin-top: 30px;
    width: calc(100% - 40px);
    margin-left: 20px;
  }
`;

const ConsultationSmallTextDesktop = styled(BodyText)`
  margin-left: 16.66%;
  margin-top: 100px;
  color: ${colors.mainColor};
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 6.93vw;
  }
`;

const ConsultationSmallTextMobile = styled(ConsultationSmallTextDesktop)`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: inline-block;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 20px;
  }
`;

const SamleProjectsWrapper = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40px);
    margin-left: 20px;
  }
`;

const SamleProjectsTitle = styled(ConsultationTitle)`
  ::before {
    content: "03";
  }
`;

const SamleProjectsSubtitle = styled(BodyText)`
  color: ${colors.white};
  margin-left: 8.33%;
  width: 41.66%;
  margin-bottom: 100px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
    width: 100%;
    margin-bottom: 30px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-bottom: 6.93vw;
  }
`;

const SamleProjectsSlider = styled(Wrapper)`
  overflow-x: auto;
  margin-left: ${sizes.xlMargin}px;
  width: calc(100% - ${sizes.xlMargin}px);
  padding-bottom: 136px;
  @media (max-width: ${mediaQuery.desktop}) {
    width: calc(100% - ${sizes.bigMargin}px);
    margin-left: ${sizes.bigMargin}px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - ${sizes.smallMargin}px);
    margin-left: ${sizes.smallMargin}px;
    padding-bottom: 50px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 6.933vw;
    margin-bottom: 4.853vw;
    margin-left: 9.706vw;
    width: calc(100% - 9.706vw);
  }
`;

const ServicesWrapper = styled(Wrapper)`
  margin-top: 100px;

  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 30px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 6.93vw;
  }
`;

const ServicesTitle = styled(StyledBigTitle)`
  ::before {
    content: "04";
  }
`;

const Descritpion04 = styled(Descritpion01)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 3.465vw;
  }
`;

const DescritpionRight04 = styled(DescritpionLeft01)`
  position: relative;
  ::after {
    content: "";
    position: absolute;
    bottom: -30px;
    width: 20%;
    background-color: ${colors.mainColor};
    height: 1px;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    ::after {
      bottom: 0px;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    ::after {
      bottom: -2.08vw;
      height: 0.069vw;
    }
  }
`;

const StagesWrapper = styled(Wrapper)``;

const StagesTitle = styled(StyledBigTitle)`
  ::before {
    content: "05";
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20px;
  }
`;

const SubTitle = styled.h4`
  color: ${colors.darkGrey};
  font-size: ${typographySizes.sm}px;
  font-weight: 700;
  margin-top: 80px;
  margin-left: 30px;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 20px;
    margin-top: 40px;

    font-size: ${typographySizes.xs}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 5.546vw;
    margin-left: 2.08vw;
    font-size: ${typographySizes.maxSm}vw;
  }
`;

const StagesList = styled.ul`
  width: 100%;
  list-style: none;
  margin-top: 15px;
  color: ${colors.darkGrey};
  font-weight: 200;

  font-size: ${typographySizes.sm}px;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.xs}px;
  }
  @media (min-width: ${mediaQuery.max}) {
    margin-top: 1.039vw;
  }
`;

const StagesElement = styled.li`
  border-top: solid 1px ${colors.midGrey};
  padding: 15px 0;
  width: 100%;
  display: flex;
  font-weight: 300;
  :last-of-type {
    border-bottom: solid 1px ${colors.midGrey};
  }
  strong {
    color: ${colors.midGrey};
    font-weight: 300;
    background-color: transparent;
  }
  p {
    font-size: ${typographySizes.sm}px;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.xs}px;
    }
  }
  @media (min-width: ${mediaQuery.max}) {
    border-top: solid 0.069vw ${colors.midGrey};
    padding: 1.039vw 0;
    :last-of-type {
      border-bottom: solid 0.069vw ${colors.midGrey};
    }
    p {
      font-size: ${typographySizes.maxSm}vw;
    }
  }
`;

const StagesNum = styled.span`
  width: 30px;
  font-size: ${typographySizes.s}px;

  color: ${colors.mainColor};
  position: relative;
  top: 1px;
  flex-shrink: 0;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 10px;
    width: 20px;
  }
  @media (min-width: ${mediaQuery.max}) {
    font-size: ${typographySizes.maxS}vw;
    width: 2.08vw;
  }
`;

class ProjektowaniePage extends React.Component {
  render() {
    return (
      <>
         <SEO title={this.props.data.strapiDesigningPage.SEO.Title}
        description={this.props.data.strapiDesigningPage.SEO.Description}/>
        <Header>
          <StyledBigTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            Color={colors.darkBlue}
          >
            {this.props.data.strapiDesigningPage.Title_01}
          </StyledBigTitle>
          <Descritpion01>
            <DescritpionLeft01
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <BodyText>
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiDesigningPage.Description_first_col_01
                  }
                />
              </BodyText>
            </DescritpionLeft01>
            <DescritpionLeft01
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <BodyText>
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiDesigningPage
                      .Description_second_col_01
                  }
                />
              </BodyText>
            </DescritpionLeft01>
          </Descritpion01>
          <StyledImg01
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            fluid={
              this.props.data.strapiDesigningPage.Img_01.childImageSharp.fluid
            }
          />
        </Header>
        <DarkBlueBg>
          <Wrapper>
            <ConsultationWrapper>
              <ConsultationSmallTextMobile
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {this.props.data.strapiDesigningPage.Bottom_text_02}
              </ConsultationSmallTextMobile>
              <ConsultationDescritpionMobile
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiDesigningPage
                      .Description_second_col_02
                  }
                />
              </ConsultationDescritpionMobile>

              <ConsultationImg
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
                fluid={
                  this.props.data.strapiDesigningPage.Img_02.childImageSharp
                    .fluid
                }
              />
              <ConsultationTextWrapper>
                <ConsultationTitle
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                  Color={colors.white}
                >
                  {this.props.data.strapiDesigningPage.Title_02}
                </ConsultationTitle>
                <ConsultationDescritpionWrapper>
                  <ConsultationDescritpion
                    data-sal="slide-up"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                  >
                    <BodyText>
                      <ReactMarkdown
                        className={"marginP"}
                        source={
                          this.props.data.strapiDesigningPage
                            .Description_first_col_02
                        }
                      />
                    </BodyText>
                  </ConsultationDescritpion>
                  <ConsultationDescritpionDesktop
                    data-sal="slide-up"
                    data-sal-easing="ease"
                    data-sal-duration="1000"
                  >
                    <BodyText>
                      <ReactMarkdown
                        className={"marginP"}
                        source={
                          this.props.data.strapiDesigningPage
                            .Description_second_col_02
                        }
                      />
                    </BodyText>
                  </ConsultationDescritpionDesktop>
                </ConsultationDescritpionWrapper>

                <ConsultationSmallTextDesktop
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  {this.props.data.strapiDesigningPage.Bottom_text_02}
                </ConsultationSmallTextDesktop>
              </ConsultationTextWrapper>
            </ConsultationWrapper>
            <SamleProjectsWrapper
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <SamleProjectsTitle Color={colors.white}>
                {this.props.data.strapiDesigningPage.Title_03}
              </SamleProjectsTitle>
              <SamleProjectsSubtitle
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="1000"
              >
                {this.props.data.strapiDesigningPage.Subtitle_03}
              </SamleProjectsSubtitle>
            </SamleProjectsWrapper>
          </Wrapper>
          <SamleProjectsSlider>
            <Slider
              blue
              imgsTexts={this.props.data.strapiDesigningPage.Sample_projects}
            />
          </SamleProjectsSlider>
        </DarkBlueBg>
        <ServicesWrapper>
          <ServicesTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            Color={colors.darkBlue}
          >
            {this.props.data.strapiDesigningPage.Title_04}
          </ServicesTitle>
          <Descritpion04>
            <DescritpionLeft01
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <BodyText>
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiDesigningPage.Description_first_col_04
                  }
                />
              </BodyText>
            </DescritpionLeft01>
            <DescritpionRight04
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="1000"
            >
              <BodyText>
                <ReactMarkdown
                  className={"marginP"}
                  source={
                    this.props.data.strapiDesigningPage
                      .Description_second_col_04
                  }
                />
              </BodyText>
            </DescritpionRight04>
          </Descritpion04>
        </ServicesWrapper>
        <StagesWrapper>
          <StagesTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            Color={colors.darkBlue}
          >
            {this.props.data.strapiDesigningPage.Title_05}
          </StagesTitle>
          <SubTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            {this.props.data.strapiDesigningPage.SubTitle_05}
          </SubTitle>
          <StagesList>
            {this.props.data.strapiDesigningPage.Stages.map(
              (document, index) => (
                <StagesElement
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
                >
                  <StagesNum>{index + 1}</StagesNum>
                  <ReactMarkdown className="text" source={document.Text} />
                </StagesElement>
              )
            )}
          </StagesList>
        </StagesWrapper>

        <Cta />
      </>
    );
  }
}

export const ProjektowanieQuery = graphql`
  query ProjektowanieQuery {
    strapiDesigningPage {
      Bottom_text_02

      Description_first_col_01
      Description_second_col_01
      Description_first_col_02
      Description_second_col_02
      Subtitle_03
      Title_01
      Title_02
      Title_03
      Img_01 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img_02 {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Sample_projects {
        Img {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        Text
      }
      SubTitle_05
      Title_04
      Description_first_col_04
      Description_second_col_04
      Title_05
      Stages {
        Text
      }
      SEO {
        Description
        Title
      }
    }
  }
`;

export default ProjektowaniePage;
